import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LottieLoader from "shared/components/LottieLoader";
import Form from "shared/components/form/form";
import showToast from "shared/components/toast/showToast";
import { routeConstant } from "shared/routes/routeConstant";
import {
  CreateLayer,
  EditLayer,
  UpdateLayer,
} from "shared/services/layerService";
import Validator from "shared/utils/validator";

const form_inputs = [
  {
    label: "Layer Name",
    type: "text",
    placeholder: "Layer Name",
    name: "layer_name",
    adjust: "col-md-6",
  },
  {
    label: "Layer Calling Name",
    type: "text",
    placeholder: "Layer Calling Name",
    name: "layer_calling_name",
    adjust: "col-md-6",
  },
  {
    label: "Layer Calling URL",
    type: "text",
    placeholder: "Layer Calling URL",
    name: "layer_calling_url",
    adjust: "col-md-6",
  },
  {
    label: "Short Description",
    type: "text",
    placeholder: "Short Description",
    name: "short_description",
    adjust: "col-md-6",
  },
  {
    label: "Description",
    type: "texteditor",
    placeholder: "Description",
    name: "description",
    adjust: "col-md-12",
    rows: 4,
  },
  {
    label: "Legend Json",
    type: "textarea",
    placeholder: "Legend Json",
    name: "legend_json",
    adjust: "col-md-12",
    rows: 10
  },
  {
    label: "Legend Query",
    type: "textarea",
    placeholder: "Legend Query",
    name: "legend_query",
    adjust: "col-md-12",
    rows: 10
  },
  {
    label: "Query Filter",
    type: "textarea",
    placeholder: "Query Filter",
    name: "query_filter",
    adjust: "col-md-12",
  },
  // {
  //   label: "Self Hosted",
  //   type: "radio",
  //   placeholder: "Self Hosted",
  //   name: "self_hosted",
  //   adjust: "col-md-6",
  //   options: [
  //     {
  //       id: 1,
  //       value: "This is self hosted",
  //     },
  //     {
  //       id: 0,
  //       value: "This is not self hosted",
  //     },
  //   ],
  // },
  {
    label: "Layer Type",
    type: "select",
    placeholder: "Select Layer Type...",
    name: "layer_type",
    adjust: "col-md-6",
    options: [
      {
        id: "WMS",
        value: "WMS",
      },
      {
        id: "Feature",
        value: "Feature",
      },
      {
        id: "Self Hosted",
        value: "Self Hosted",
      },
      {
        id: "Tile",
        value: "Tile",
      },
    ],
  },
  {
    label: "Portal Item ID",
    type: "text",
    placeholder: "Portal Item ID",
    name: "portal_item_id",
    adjust: "col-md-6",
  },

  {
    label: "Status",
    type: "radio",
    placeholder: "Status",
    name: "status",
    adjust: "col-md-6",
    options: [
      {
        id: 1,
        value: "Active",
      },
      {
        id: 0,
        value: "Deactive",
      },
    ],
  },
  {
    label: "User Based Filters",
    type: "radio",
    placeholder: "Status",
    name: "user_based_filters",
    adjust: "col-md-6",
    options: [
      {
        id: 1,
        value: "Yes",
      },
      {
        id: 0,
        value: "No",
      },
    ],
  },
  {
    label: "Image",
    type: "file",
    placeholder: "Image of layer",
    name: "image",
    adjust: "col-md-12",
  },
];

const LayerForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const containsView = location.pathname.includes("view");
  const [formHeading, setFormHeading] = useState("");
  const [formSaveButtonText, setFormSaveButtonText] = useState("");
  const [loading, setLoading] = useState(true);
  const [fieldsDefaultValues, setFieldsDefaultValues] = useState({
    layer_name: "",
    layer_calling_name: "",
    layer_calling_url: "",
    short_description: "",
    description: "",
    legend_query: "",
    legend_json: "",

    query_filter: "",
    self_hosted: "",
    portal_item_id: "",
    layer_type: "",
    image: "",
    status: "",
    user_based_filters: "",
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      setLoading(true);
      EditLayer(id)
        .then(({ data: { status, data, message } }) => {
          if (status) {
            setFieldsDefaultValues({
              layer_name: data.layer_name,
              layer_calling_name: data.layer_calling_name,
              layer_calling_url: data.layer_calling_url,
              short_description: data.short_description,
              description: data.description,
              legend_query: data.legend_query,
              legend_json: data.legend_json,

              query_filter: data.query_filter,
              self_hosted: data.self_hosted,
              portal_item_id: data.portal_item_id,
              layer_type: data.layer_type,
              image: data.image ? data.base_url + data.image : null,
              status: data.status,
              user_based_filters: data.user_based_filters,
            });
          } else {
            showToast("error", message);
            navigate(routeConstant.private.dashboard.path);
          }
        })
        .catch((err) => {
          showToast("error", err?.response?.data?.message);
        })
        .finally(() => {
          setLoading(false);
        });

      setFormHeading(routeConstant.private.editLayer.title);
      setFormSaveButtonText(routeConstant.private.editLayer.title);
      if (containsView) {
        setFormHeading(routeConstant.private.viewLayer.title);
      }
    } else {
      setLoading(false);

      setFieldsDefaultValues({
        layer_name: "",
        layer_calling_name: "",
        layer_calling_url: "",
        short_description: "",
        description: "",
        legend_query: "",
        legend_json: "",

        query_filter: "",
        self_hosted: "",
        portal_item_id: "",
        layer_type: "",
        image: "",
        status: "",
        user_based_filters: "",
      });
      setFormHeading(routeConstant.private.addResource.title);
      setFormSaveButtonText(routeConstant.private.addResource.title);
    }
  }, [id, containsView]);

  return (
    <>
      {loading ? (
        <LottieLoader />
      ) : (
        // <div
        //   style={{
        //     position: "fixed",
        //     top: "50%",
        //     left: "50%",
        //     transform: "translate(-50%, -50%)",
        //     zIndex: 9999,
        //     backgroundColor: "#f5f8fb",
        //     borderRadius: "8px",
        //     padding: "20px",
        //   }}
        // >
        //   <Spinner animation="border" role="status" />
        // </div>
        <Form
          form_inputs={form_inputs}
          fields_default_values={fieldsDefaultValues}
          validator={Validator.layerSchema}
          form_heading={formHeading}
          form_save_button_text={formSaveButtonText}
          view_only={containsView}
          create_service={CreateLayer}
          update_service={UpdateLayer}
          form_id={id}
          redirect_service_success_path={routeConstant.private.layer.path}
        />
      )}
    </>
  );
};

export default LayerForm;
